<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Müşteri SMS Onay</b-card-title>
        <b-button
          variant="primary"
          to="/service/sms/search"
        >
          <FeatherIcon icon="PlusIcon" />
          Oluştur
        </b-button>
      </b-card-header>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="getData"
        striped
      >
        <template #cell(customer)="data">
          <div class="font-small-2">
            {{ data.item.customer }}
          </div>
        </template>
        <template #cell(created)="data">
          <div class="font-small-2 font-weight-bold text-nowrap">
            <div>{{ data.item.user }}</div>
            {{ moment(data.item.created).format('lll') }}
          </div>
        </template>
        <template #cell(status)="data">
          <div>
            <b-badge
              :variant="data.item.status == 1 ? 'success' : 'danger'"
              class="font-small-2"
            >
              {{ data.item.status == 1 ? 'Onaylandı' : 'Onaylanmadı' }}
            </b-badge>
          </div>
        </template>
        <template #cell(control)="data">
          <b-button
            :to="'/service/sms/view/' + data.item.id"
            variant="outline-primary"
            block
            size="sm"
          >
            Görüntüle
          </b-button>
        </template>
      </b-table>
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="getDataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BButton,
  BBadge,
  BCardFooter,
  BTable,
  BPagination,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
    BBadge,
    BTable,
    BCardFooter,
    BPagination,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      dataQuery: {
        select: [
          'com_service_sms.id AS id',
          'com_customer.name AS customer',
          'com_user.name AS user',
          'com_service_sms.status AS status',
          'com_service_sms.created AS created',
        ],
        limit: 25,
        start: 0,
      },
      fields: [
        {
          key: 'customer',
          label: 'Müşteri Adı',
        },
        {
          key: 'created',
          label: 'Gönderilme',
        },
        {
          key: 'status',
          label: 'Durum',
        },
        {
          key: 'control',
          label: '',
          thStyle: { width: '180px' },
        },
      ],
    }
  },
  computed: {
    getData() {
      return this.$store.getters['serviceSms/getServiceSms']
    },
    getDataCount() {
      return this.$store.getters['serviceSms/getServiceSmsCount']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 25
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('serviceSms/smsList', this.dataQuery)
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
  },
}
</script>
